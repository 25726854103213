.loader-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #030303;
  z-index: 99999;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  font-size: 0;
  color: #00c9d0;
  display: inline-block;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.loader div {
  background-color: #d9b06a;
  display: inline-block;
  float: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  opacity: 0.5;
  border-radius: 50%;
  -webkit-animation: ballPulseDouble 2s ease-in-out infinite;
  animation: ballPulseDouble 2s ease-in-out infinite;
}

.loader div:last-child {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes ballPulseDouble {

  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes ballPulseDouble {

  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.video-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-container video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: 1;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 3;
}

.overlay-content h1 {
  margin-bottom: 1rem;
}

.overlay-content p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.overlay-content a {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #ff6600;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.overlay-content a:hover {
  background-color: #ff4500;
}

.box {
  position: relative;
  color: #fff;
  filter: drop-shadow(0.125rem 0.125rem 0.125rem #888888);
}


.bg-yellow-100 {
  background-color: #FFA800;
  color: #000
}

.theme_border {
  border-color: #FFA800 !important;
}


.ribbon-wrapper {
  width: 150px;
  height: 100px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.ribbon {
  text-align: center;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 3px 0;
  left: -38px;
  font-size: 15px;
  top: 25px;
  /* width: 150px; */
  background-color: #34A853;
  color: white;
}

.px-9 {
  padding-left: 5.6rem;
  padding-right: 2.7rem;
}



/*  profile css */



.card {
  position: relative;
  width: 350px;
  height: 145px;
  background: #333;
  transition: 0.5s;
}



.card .lines {
  position: absolute;
  inset: 0px;
  background: #000;
  overflow: hidden;
}

.card .lines:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 120px;
  background: linear-gradient(transparent, #fff, #fff, #fff, transparent);
  animation: animate 4s linear infinite;
}


@keyframes animate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg)
  }
}

.card .lines::after {
  content: "";
  position: absolute;
  inset: 3px;
  background: #292929;
}

.card .imgBx {
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 150px;
  background-color: black;
  z-index: 10;
  overflow: hidden;
  transition: 0.5s;
}



.card .imgBx::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 150px;
  background: linear-gradient(transparent, #ff9d3c, #ff9d3c, #ff9d3c, transparent);
  animation: animate2 4s linear infinite;
}

@keyframes animate2 {
  0% {
    transform: translate(-50%, -50%) rotate(360deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0deg)
  }
}

.card .imgBx::after {
  content: "";
  position: absolute;
  inset: 3px;
  background: #292929;
}

.card .imgBx img {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  width: calc(100% - 20px);
  height: calc(100% - 20px);
  filter: grayscale(1);
  transition: 0.5s;
}

.card .imgBx:hover img {
  filter: none;
}

.card .content {
  display: flex;

  align-items: flex-end;
}

.card .content .details {
  text-align: center;
  width: 100%;
  transition: 0.5s;
  transform: translateY(145px);
}


.card .content .details h2 {
  font-size: 1.25em;
  font-weight: 600;
  color: #FFA800;
  line-height: 1.2em;
}

.card .content .details h2 span {
  font-size: 0.75em;
  font-weight: 500;
  color: #fff;
}

.card .content .details .data {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.card .content .details .data h3 {
  font-size: 1em;
  color: #45f3ff;
  line-height: 1.2em;
  font-weight: 600;
}

.card .content .details .data h3 span {
  font-size: 0.85em;
  font-weight: 400;
  color: #fff;
}


.border_warning {
  border: 1px solid #FFA800;
}